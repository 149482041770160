import React from "react"

import Layout from "layout"

import HomeBanner from "components/StaticPages/Home/HomeBanner"
import HomeAbout from "../components/StaticPages/Home/HomeAbout"
import HomeBenefits from "../components/StaticPages/Home/HomeBenefits"
import HomeEligibility from "../components/StaticPages/Home/HomeEligibility"
import HomeProgram from "../components/StaticPages/Home/HomeProgram"
import { hasSignedInUser } from "../components/Auth/services/user"
import AboutBenefits from "../components/StaticPages/About/components/AboutBenefits"

const IndexPage = () => {
  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Saxenda Early Experience Program"
    >
      <HomeBanner />
      <HomeAbout />
      {!!hasSignedInUser() && <AboutBenefits type="patient" />}
      <HomeProgram />
      <HomeBenefits />
      <HomeEligibility />
    </Layout>
  )
}

export default IndexPage
