import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Hero from "layout/Hero"
import Container from "layout/Container"

const HomeAbout = () => {
  const data = useStaticQuery(graphql`
    {
      home__2: file(relativePath: { eq: "pages/general__about.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const home2 = data?.home__2?.childImageSharp?.fluid
  return (
    <Hero color="tertiary" size="small" style={{ marginTop: "-1rem" }}>
      <Container isCentered desktop={12} fullhd={10}>
        <section
          className="columns py-2 is-relative p-3 p-0-mobile"
          style={{ zIndex: "1" }}
        >
          <div className="column is-one-half content mx-0 px-0 is-vcentered">
            <h2 className="has-text-primary">
              What is the Liraglutide (Saxenda<sup>®</sup>) Early Experience and
              Patient Support Program?
            </h2>
            <p>
              The Patient Support Program gives patients access to Liraglutide
              (Saxenda®) at a discounted rate. This aims to provide patients
              with a novel therapeutic option to manage excess weight through
              appetite regulation.
            </p>
            <p>
              In the program, doctors gain clinical experience in treating
              patients with elevated body mass indices (BMIs) and weight-related
              diseases.
            </p>
          </div>
          <div className="column is-one-half is-clipped is-tablet-hidden"></div>
        </section>
        <div className="is-hidden-tablet py-6">
          <div
            className="is-absolute is-bottom is-left"
            style={{ width: "100%", overflow: "clip" }}
          >
            <Img fluid={home2} />
          </div>
        </div>
        <div>
          <div
            className="is-hidden-mobile is-absolute is-bottom is-right"
            style={{ width: "50%", overflow: "clip" }}
          >
            <Img fluid={home2} />
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeAbout
