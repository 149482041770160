import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import HeroBackground from "../../../../static/images/pages/general__hero.svg"

import Hero from "layout/Hero"
import Container from "layout/Container"
import ctas from "../utils/homeCTA.json"
import HeroCard from "../../Elements/HeroCard"
import { hasSignedInUser } from "../../Auth/services/user"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      saxenda: file(relativePath: { eq: "logos/saxenda.png" }) {
        childImageSharp {
          fixed(height: 34) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stethoscope: file(relativePath: { eq: "icons/stethoscope.png" }) {
        childImageSharp {
          fixed(height: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      healthcare: file(relativePath: { eq: "icons/healthcare.png" }) {
        childImageSharp {
          fixed(height: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const saxenda = data?.saxenda?.childImageSharp.fixed
  const healthcare = data?.healthcare?.childImageSharp.fixed
  const stethoscope = data?.stethoscope?.childImageSharp.fixed
  return (
    <Hero size="fullheight">
      {/* <div
        className="is-absolute is-bottom is-left is-hidden-desktop"
        style={{ zIndex: "-1" }}
      >
        <HeroBackground />
      </div> */}
      <div className="is-absolute is-bottom is-right" style={{ zIndex: "-1" }}>
        <HeroBackground />
      </div>

      <Container isCentered desktop={12} fullhd={10} maximize={true}>
        <div className="columns is-full" id="hello">
          <div className="column">
            <Img fixed={saxenda} />
            <p className="mb-1">
              The Liraglutide (Saxenda<sup>®</sup>) Patient Support Program is a
              weight management and medicine adherence program
            </p>
          </div>
        </div>
        {!hasSignedInUser() ? (
          <div className="columns is-full mb-0-mobile mb-4">
            <div className="column is-full-mobile is-half-desktop">
              <HeroCard
                title={"I am a patient"}
                link={"/patient"}
                img={healthcare}
                description={
                  <p>
                    Learn more about why you were endorsed to this Patient
                    Support Program.
                  </p>
                }
                ctas={ctas["patient"]}
              />
            </div>
            <div className="column is-full-mobile is-half-desktop">
              <HeroCard
                title={"I am a doctor"}
                link={"/doctor"}
                img={stethoscope}
                description={
                  <p>
                    Learn more about why you were endorsed to this Patient
                    Support Program.{" "}
                  </p>
                }
                ctas={ctas["doctor"]}
              />
            </div>
          </div>
        ) : (
          <div className="columns is-full mb-0-mobile mb-4">
            <div className="column is-half-desktop">
              <HeroCard
                title={"Order Now"}
                link={"/epharmacy/upload"}
                img={healthcare}
                description={
                  <p>
                    Order Liraglutide (Saxenda<sup>®</sup>) today
                  </p>
                }
              />
            </div>
            <div className="column is-half-desktop"></div>
          </div>
        )}
      </Container>
    </Hero>
  )
}

export default HomeBanner
