import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"

import ContentItem from "../../Elements/ContentItem"

const HomeProgram = () => {
  const data = useStaticQuery(graphql`
    {
      saxenda_hand: file(relativePath: { eq: "pages/home__saxenda_hand.png" }) {
        childImageSharp {
          fixed(width: 390) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hex_bg: file(relativePath: { eq: "pages/home__polygon-bg.png" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const hex_bg = data?.hex_bg?.childImageSharp?.fluid?.src
  const saxenda_hand = data?.saxenda_hand?.childImageSharp?.fixed

  const content = {
    title: (
      <Fragment>
        What can you <span className="has-text-secondary">expect</span> from the
        program?
      </Fragment>
    ),
    item: {
      experience: {
        heading: (
          <h3 className="has-text-primary mb-1">
            Experience Liraglutide (Saxenda<sup>®</sup>)
          </h3>
        ),
        body: (
          <p>
            The program provides discounts for Liraglutide (Saxenda<sup>®</sup>)
            and enables patients to directly receive Liraglutide (Saxenda
            <sup>®</sup>) from their homes.
          </p>
        ),
      },
      educare: {
        heading: <h3 className="has-text-primary mb-1">You are not alone</h3>,
        body: (
          <p>
            Patients will have access to educational resources and a direct
            hotline to nurse educators through EduCare. Nurse educators will be
            available to teach patients on weight management, how to use the
            product, and other medical concerns the patient may have.
          </p>
        ),
      },
    },
  }

  return (
    <Hero size="small">
      <Container isCentered desktop={12} fullhd={10}>
        <section
          className="pt-6 pb-2 is-relative px-3 px-0-mobile"
          style={{ minWidth: "100%" }}
        >
          <h2 className="has-text-primary has-text-centered pb-4">
            {content.title}
          </h2>

          <div className="mb-2">
            <ContentItem
              title={content.item.experience.heading}
              content={content.item.experience.body}
              img={saxenda_hand}
              invertImg={true}
            />
          </div>
          <div className="mb-2">
            <ContentItem
              title={content.item.educare.heading}
              content={content.item.educare.body}
              direction="Right"
              borderColor="#9EC5C5"
            />
          </div>
        </section>
      </Container>
    </Hero>
  )
}

export default HomeProgram
