import React from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"

const HomeEligibility = () => {
  const inclusions = [
    "You are an adult patient (18 years old and above) with a BMI of ≥ 27 who also have weight-related medical problems",
    "You have a valid prescription for Liraglutide (Saxenda®)",
    "Your prescribing doctor is enrolled in the program",
    "You were prescribed in accordance with local approved indications",
    "You have a delivery address within the Philippines",
  ]

  const exclusions = [
    "You have a known hypersensitivity to Liraglutide (Saxenda®) or any of its excipients",
    "You are currently taking medicines that are GLP-1 receptor agonists",
    "You are pregnant, currently lactating, or are planning to be pregnant",
  ]

  return (
    <Hero size="small" flexOverride={true}>
      <Container desktop={12} fullhd={10} isCentered>
        <section
          className="p-3 p-0-mobile py-3-mobile is-relative"
          style={{ width: "100%" }}
        >
          <h2 className="is-hidden-mobile mb-2 has-text-primary">
            Who can use Liraglutide (Saxenda<sup>®</sup>)?
          </h2>
          <h3 className="mb-0.5  has-text-secondary">Inclusion Criteria</h3>
          <p className="mb-1">
            You are <strong>eligible</strong> to join the program if:
          </p>
          <ul class="check-bullet">
            {inclusions.map((item) => (
              <li
                className="mb-0.5"
                dangerouslySetInnerHTML={{
                  __html: item.replace("®", "<sup>®</sup>"),
                }}
              ></li>
            ))}
          </ul>
          <h3 className="mt-2 mb-0.5  has-text-grey">Exclusion Criteria</h3>
          <p className="mb-1">
            You are <strong>not recommended</strong> to use Liraglutide (Saxenda
            <sup>®</sup>) if:
          </p>
          <ul class="x-mark-bullet">
            {exclusions.map((item) => (
              <li
                className="mb-0.5"
                dangerouslySetInnerHTML={{
                  __html: item.replace("®", "<sup>®</sup>"),
                }}
              ></li>
            ))}
          </ul>
        </section>
      </Container>
    </Hero>
  )
}

export default HomeEligibility
