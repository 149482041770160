import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import styles from "./utils/elements.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const HeroCard = ({ title, description, link }) => {
  return (
    <a href={link ? link : "#"}>
      <div
        className={classNames(
          styles["heroCard"],
          "px-1 py-2 is-flex is-flex-direction-column"
        )}
        style={{ zIndex: "5" }}
      >
        <h3 className="mb-1 has-text-weight-bold has-text-primary">{title}</h3>
        {description && (
          <div className="is-flex is-justify-content-space-between	">
            <p className="mb-1">{description}</p>
            <h2 className="has-text-secondary ml-2">
              <FontAwesomeIcon icon={faChevronRight} />
            </h2>
          </div>
        )}
        <div style={{ zIndex: "1" }} className="is-relative">
          {/* {ctas?.length > 0 &&
          ctas.map((item) => {
            return (
              <Link
                to={item.url}
                className={classNames(
                  `button-size mb-1`,
                  styles["heroCard__links"],
                  item?.variant === "outline"
                    ? `has-text-${item?.style} ${styles["secondary_buttonOutline"]}`
                    : `is-${item?.style} ${styles["secondary_button"]}`
                )}
              >
                {item.label}
              </Link>
            )
          })} */}
        </div>
      </div>
    </a>
  )
}

export default HeroCard
