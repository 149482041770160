import React, { Fragment } from "react"
import Img from "gatsby-image"
import styles from "./utils/elements.module.scss"
import classNames from "classnames"
import Container from "../Layout/Container"

const ContentItem = ({
  title,
  content,
  img,
  direction = "Left",
  borderColor = "#DBDBDB",
  invertImg = false,
}) => {
  let inlineStyling = {}
  inlineStyling[`border${direction}`] = "transparent"

  return (
    <div
      className={classNames(
        "is-relative py-3",
        styles["contentItem"],
        styles[`contentItem${direction}`]
      )}
      style={{ ...inlineStyling, borderColor }}
    >
      <Container isCentered fullhd={6} desktop={8}>
        {title}
        {content}
      </Container>
      {img && (
        <div
          className={classNames(
            styles["contentItemImg"],
            "is-flex is-hidden-touch"
          )}
        >
          <div
            style={{
              transform: !!invertImg ? "scaleX(-1)" : "scaleX(1)",
            }}
          >
            <Img fixed={img} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ContentItem
