import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BenefitBG from "../../../../static/images/pages/general__benefits.svg"

import Hero from "layout/Hero"
import Container from "layout/Container"

const HomeBenefits = () => {
  const data = useStaticQuery(graphql`
    {
      saxenda_hand: file(relativePath: { eq: "pages/home__saxenda_hand.png" }) {
        childImageSharp {
          fixed(width: 390) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      home_shape_2: file(relativePath: { eq: "pages/home__shape_2.png" }) {
        childImageSharp {
          fixed(width: 240) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      home_jeans: file(relativePath: { eq: "pages/home__jeans.png" }) {
        childImageSharp {
          fixed(width: 390) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      home_bg_3: file(relativePath: { eq: "pages/home__bg_3.png" }) {
        childImageSharp {
          fixed(width: 390) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hex_bg: file(relativePath: { eq: "pages/home__polygon-bg.png" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const saxenda_hand = data?.saxenda_hand?.childImageSharp?.fixed
  const home_shape_2 = data?.home_shape_2?.childImageSharp?.fixed
  const home_jeans = data?.home_jeans?.childImageSharp?.fixed
  const home_bg_3 = data?.home_bg_3?.childImageSharp?.fixed
  const hex_bg = data?.hex_bg?.childImageSharp?.fluid?.src

  const benefits = [
    <p className="">
      Addresses one of your body's natural responses to weight loss by{" "}
      <strong>regulating the hormones responsible for your appetite</strong>
    </p>,
    <p className="">
      Aids in losing and keeping off excess weight when{" "}
      <strong>used with a reduced-calorie die</strong> and{" "}
      <strong>increased physical activity.</strong>
    </p>,
    <p className="">
      May help in <strong>improving weight-related conditions</strong> such as
      high blood pressure and high cholesterol.
    </p>,
  ]

  return (
    <Hero size="small">
      <div className="is-absolute is-right is-top">
        <Img fixed={saxenda_hand} />
      </div>
      <div className="is-absolute is-left is-bottom is-flex is-flex-direction-column is-hidden-tablet">
        <Img fixed={home_shape_2} style={{ zIndex: "1" }} />
        <Img fixed={home_jeans} style={{ marginTop: "-50px", zIndex: "0" }} />
      </div>
      <div className="is-absolute is-left is-bottom is-hidden-mobile">
        <BenefitBG />
      </div>
      <Container isCentered desktop={12} fullhd={10}>
        <section className="pt-6 pb-2 is-relative px-3 px-0-mobile">
          <h2 className=" mb-1 has-text-primary">
            What is Liraglutide (Saxenda<sup>®</sup>)?
          </h2>
          <p>
            Liraglutide (Saxenda<sup>®</sup>) is a once-daily, self-injectable
            prescription medication used for weight management. It comes in the
            form of a prefilled pen which allows you to select the dose you need
            to administer.
          </p>
          <h4
            className="my-2  has-text-primary is-relative"
            style={{ zIndex: "1" }}
          >
            Benefits:
          </h4>
          <div className="columns is-centered">
            {benefits.map((item) => (
              <div
                className="column is-one-fourth mb-1 has-background-white mx-1"
                style={{
                  position: "relative",
                  border: "1px solid #6EA9A9",
                  borderRadius: "10px",
                  zIndex: "2",
                }}
              >
                {item}
              </div>
            ))}
          </div>{" "}
          <div className="is-flex has-text-centered is-hidden-tablet">
            <div className="column is-one-third"></div>
            <div className="column is-two-thirds">
              <h3 className="my-2  has-text-primary">
                Who can use
                <br />
                Liraglutide
                <br />
                (Saxenda<sup>®</sup>)?
              </h3>
            </div>
          </div>
        </section>
        <div className="is-absolute is-right is-bottom is-hidden-tablet">
          <Img fixed={home_bg_3} style={{ zIndex: "-2" }} />
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBenefits
